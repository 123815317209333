import { Calendar, momentLocalizer, dateFnsLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useEffect, useState } from "react";
import EventModal from "./EventModal";
import { useAuth } from "../../../context/useAuth";
import LogoutModal from "../../logoutModal/LogoutModal";
import { ScaleLoader } from "react-spinners";
import DataTable from "react-data-table-component";

const CalendarComponent = () => {
  const localizer = momentLocalizer(moment);
  const token = useAuth().token;
  const programId = useAuth().programId;
  const base_url = process.env.REACT_APP_BASE_URL;

  const [data, setData] = useState([]);
  const [eventSelected, SetEventSelected] = useState(null);
  const [isOpenEvent, setIsOpenEvent] = useState(false);
  const [selectedFilter, setSeletedFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [department, setDepartments] = useState([]);
  const [isExpire, setIsExpire] = useState(false);
  const [loading, setLoading] = useState(false);
  const [todayAppCount, setTodayAppCount] = useState(0);

  const minTime = new Date();
  minTime.setHours(7, 0, 0);

  const maxTime = new Date();
  maxTime.setHours(20, 0, 0);

  
  const agendaColumns = [
    {
      name: `Time`,
      selector: (row) => (
        `${moment(row.start).format("hh:mm A")} - ${moment(row.end).format("hh:mm A")}`
      ),
      sortable: true,
      width: "20%",
    },
    {
      name: `Client Name`,
      selector: (row) => row.title,
      sortable: true,
      width: "30%",
    },
    {
      name: `Department`,
      selector: (row) => row.name,
      sortable: true,
      width: "25%",
    },
    {
      name: `Sub Department`,
      selector: (row) => row.sub_department_name,
      sortable: true,
      width: "25%",
    }
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    rows: {
      style: {
        fontSize: "15px", 
        color: "black", 
        cursor: "pointer",
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
    
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.departmentColor || row.subDepartmentColor, // Condition to apply styles
      style: (row) => ({
        backgroundColor: row.subDepartmentColor || row.departmentColor, // Check subDepartmentColor first, then departmentColor
        color: "white",
        "&:hover": {
          backgroundColor: row.subDepartmentColor || row.departmentColor, // Same logic for hover
          color: "white",
        },
      }),
    },
  ];


  function convertToDateTime(dateString, timeString) {
    let newDate = dateString.split("T")[0];

    let newMergeDate = new Date(newDate + " " + timeString);

    return newMergeDate;
  }

  const getAppointments = async () => {
    try {
      const payload = {
        programId: programId,
      }

      const response = await fetch(`${base_url}/appointment/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      let data = await response.json();
      console.log(`data : spp `, data)

      for (var i = 0; i < data.length; i++) {
        var datetime1 = convertToDateTime(
          (await data)[i]["date"],
          (await data)[i]["start_time"]
        );
        var datetime2 = convertToDateTime(
          (await data)[i]["date"],
          (await data)[i]["end_time"]
        );
        data[i].StartTime1 = datetime1;
        data[i].EndTime1 = datetime2;
      }

      updateTimeString(data);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const updateTimeString = (result) => {
    const updatedData = result.map((item) => {
      let stTime = item.StartTime1;
      let endTime = item.EndTime1;

      return {
        ...item,
        start: stTime,
        end: endTime,
        title: item.client_name,
      };
    });
    setData(updatedData);
    setFilteredData(updatedData);
    setLoading(false);
  };

  const getDepartments = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${base_url}/department/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      let data = await response.json();
      setDepartments(data);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
    return;
  };

  const handleEventClick = (event) => {
    event.ExcusedAbsence = event.excused_absence_comment;
    event.note = event.notes;
    SetEventSelected(event);
    setIsOpenEvent(true);
  };

  const handleChangeFilter = (event) => {
    const selectedValue = event.target.value;
    setSeletedFilter(selectedValue);

    if (selectedValue === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter(
        (item) => item.department_id === parseInt(selectedValue)
      );
      setFilteredData(filtered);
    }
  };

  const handleResetFilter = () => {
    setFilteredData(data);
    setSeletedFilter("");
  };

  const handleOpenEvent = () => {
    setIsOpenEvent(!isOpenEvent);
  };

  function updateAttendenceAPI(id, attand, remark) {

    if (attand === -1) {
      const updatedItems = filteredData.filter(item => item.id !== id);
      setFilteredData(updatedItems)
    } else {
      setFilteredData(prev => prev.map(att => att.id === id ? { ...att, attendance: attand, excused_absence_comment: remark } : att))
    }
    handleOpenEvent();
  }

  const eventStyle = (event) => {
    return {
      style: {
        backgroundColor: event.subDepartmentColor !== null ? event.subDepartmentColor : event.departmentColor,
        color: "white",
      },
    };
  };

  useEffect(() => {
    getDepartments()
      getAppointments();

  }, []);


  const CustomAgendaView = ({ date, events }) => {
    const start = moment(date).startOf("day");
    const end = moment(date).endOf("day");

    // Filter events for the selected day
    const filteredEvents = events.filter((event) =>
      moment(event.start).isBetween(start, end, "minute", "[]")
    );

    setTodayAppCount(filteredEvents.length)

    return (
      <div className="p-3">
        <DataTable
                  customStyles={customStyles}
                  highlightOnHover
                  onRowClicked={handleEventClick}
                  columns={agendaColumns}
                  data={filteredEvents}
                  conditionalRowStyles={conditionalRowStyles}
                />
      </div>
    );
  };

  // Add `title` and `range` to the custom view
  CustomAgendaView.title = (date) => `Agenda (${todayAppCount}) : ${moment(date).format("MMMM Do YYYY")}`;
  CustomAgendaView.range = (date) => {
    const start = moment(date).startOf("day").toDate();
    const end = moment(date).endOf("day").toDate();
    return [start, end];
  };

  CustomAgendaView.navigate = (date, action) => {
    switch (action) {
      case "NEXT":
        return moment(date).add(1, "day").toDate();
      case "PREV":
        return moment(date).subtract(1, "day").toDate();
      case "TODAY":
        return new Date();
      default:
        return date;
    }
  };


  return (
    <>
      <div>
        {isExpire && (
          <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
        )}
        <h3 className="pt-4">Calendar</h3>
        <hr />

        <div className="d-flex justify-content-center mb-3 ">
          <select
            className="form-select w-25 me-3 "
            value={selectedFilter}
            onChange={handleChangeFilter}
          >
            <option value="">All Departments</option>
            {department.map((dept, index) => {
              return (
                <>
                  <option key={index} value={dept.id}>
                    {dept.name}
                  </option>
                </>
              );
            })}
          </select>
          <button
            className="btn btn-primary"
            style={{ width: "150px" }}
            onClick={handleResetFilter}
          >
            Reset Filter
          </button>
        </div>
        {
          loading === true ? (<>
            <div className="w-100 h-100 d-flex align-items-center justify-content-center"><ScaleLoader size={60} />
            </div></>) : (
            <>
              <Calendar
                localizer={localizer}
                events={filteredData}
                selectable={true}
                startAccessor="start"
                endAccessor="end"
                onSelectEvent={handleEventClick}
                style={{ height: "750px" }}
                eventPropGetter={eventStyle}
                min={minTime}
                max={maxTime}
                views={{
                  month: true,
                  // week: true,
                  // day: true,
                  agenda: CustomAgendaView
                }}
                defaultView="agenda"
              /></>
          )
        }

        {eventSelected && (
          <EventModal
            handleOpenEvent={handleOpenEvent}
            isOpen={isOpenEvent}
            event={eventSelected}
            updateAttendenceAPI={updateAttendenceAPI}
          />
        )}
      </div>
    </>
  );
};

export default CalendarComponent;
