import React, { useState } from "react";
import { CSVDownload } from "react-csv";
import moment from "moment";
import EventModal from "../calendar/EventModal";
import DataTable from "react-data-table-component";
import { useAuth } from "../../../context/useAuth";
import { toast } from "react-toastify";
import LogoutModal from "../../logoutModal/LogoutModal";
import { useEffect } from "react";

const FilterTable = ({ report, toggleVisibility }) => {
  const [download, setDownload] = useState(false);
  const [eventSelected, SetEventSelected] = useState(null);
  const [isOpenEvent, setIsOpenEvent] = useState(false);
  const role = useAuth().role;
  const [allReport, setAllReport] = useState(report[0]);
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = useAuth().token;
  const [isExpire, setIsExpire] = useState(false);
  // console.log(`report : `, report[0])

  useEffect(() => {
    setAllReport(report[0]);
  }, [report]);

  const dateSort = (rowA, rowB) => {
    // console.log(rowA, rowB);
    const dateA = new Date(rowA.date);
    const dateB = new Date(rowB.date);
    return dateA - dateB; // Ascending order
  };

  const handleDelete = async (appId) => {
    if (
      window.confirm("Are you sure you want to delete this appointment?") ==
      false
    ) {
      toast.info("appointment deletion cancelled");
      return;
    }

    const apiUrl = `${base_url}/appointment/delete/${appId}`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        toast.success("Appointment deleted successfully");
        // setAllReport(prev => prev.map(att => att.id === appId ? { ...att, attendance: 1 } : att))
        const updatedItems = allReport.filter((item) => item.id !== appId);
        setAllReport(updatedItems);
        return response.json();
      })
      .catch((error) => {
        toast.error("Error deleting appointment");
        console.error("Error sending data:", error);
      })
      .finally(() => {});
  };

  const AdminColumns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Client",
      selector: (row) => row.client_name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Department",
      selector: (row) => row.department_name,
      sortable: true,
      width: "180px",
    },
    {
      name: "Sub Department	",
      selector: (row) => row.sub_department_name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Date",
      selector: (row) => convertTimeToDate(row.date),
      sortable: true,
      width: "150px",
      sortFunction: dateSort,
    },
    {
      name: "Start Time",
      selector: (row) => convertTimeToHHMM(row.start_time),
      sortable: true,
      width: "130px",
    },
    {
      name: "End Time",
      selector: (row) => convertTimeToHHMM(row.end_time),
      sortable: true,
      width: "120px",
    },
    {
      name: "Attendance",
      selector: (row) =>
        row.attendance === 1
          ? row.excused_absence_comment === null
            ? "Present"
            : "Excused Absence"
          : row.attendance === 0
          ? "Absent"
          : new Date(row.date) <= new Date()
          ? "Unchecked"
          : "Future Booking",
      sortable: true,
      width: "150px",
    },
    {
      name: "Excused Absence",
      selector: (row) => row.excused_absence_comment,
      sortable: true,
    },
    {
      name: "Updated By",
      selector: (row) => row.username,
      sortable: true,
      width: "150px",
    },
    {
      name: "Updated Time",
      selector: (row) =>
        `${new Date(row.updated_at).toLocaleString("en-US", {
          timeZone: "America/New_York",
        })} ET`,
      sortable: true,
      width: "250px",
    },
    {
      name: "Action",
      cell: (row) => (
        <button className="btn btn-danger" onClick={() => handleDelete(row.id)}>
          Delete
        </button>
      ),
      width: "150px",
    },
  ];

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "70px",
    },
    {
      name: "Client",
      selector: (row) => row.client_name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Department",
      selector: (row) => row.department_name,
      sortable: true,
      width: "180px",
    },
    {
      name: "Sub Department	",
      selector: (row) => row.sub_department_name,
      sortable: true,
      width: "200px",
    },
    {
      name: "Date",
      selector: (row) => convertTimeToDate(row.date),
      sortable: true,
      width: "150px",
      sortFunction: dateSort,
    },
    {
      name: "Start Time",
      selector: (row) => convertTimeToHHMM(row.start_time),
      sortable: true,
      width: "130px",
    },
    {
      name: "End Time",
      selector: (row) => convertTimeToHHMM(row.end_time),
      sortable: true,
      width: "120px",
    },
    {
      name: "Attendance",
      selector: (row) =>
        row.attendance === 1
          ? row.excused_absence_comment === null
            ? "Present"
            : "Excused Absence"
          : row.attendance === 0
          ? "Absent"
          : new Date(row.date) <= new Date()
          ? "Unchecked"
          : "Future Booking",
      sortable: true,
      width: "150px",
    },
    {
      name: "Excused Absence",
      selector: (row) => row.excused_absence_comment,
      sortable: true,
    },
    {
      name: "Updated By",
      selector: (row) => row.username,
      sortable: true,
      width: "150px",
    },
    {
      name: "Updated Time",
      selector: (row) =>
        `${new Date(row.updated_at).toLocaleString("en-US", {
          timeZone: "America/New_York",
        })} ET`,
      sortable: true,
      width: "250px",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    rows: {
      style: {
        fontSize: "15px", // Row text size
        color: "black", // Row text color
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  const handleOpenEvent = () => {
    setIsOpenEvent(!isOpenEvent);
  };

  function updateAttendenceAPI() {
    // handleClient(clientDropdownValue);
    // getUnCheckedAppointments();
  }

  function convertTimeToDate(dateString) {
    const newDate = moment(dateString, "YYYY-MM-DD").format("MMM Do YYYY");
    return newDate;
  }

  function handleEventClick(row) {
    row.name = row.department_name;
    row.user_name = row.username;
    SetEventSelected(row);
    setIsOpenEvent(true);
  }

  function convertTimeToHHMM(timeString) {
    const newTime = moment(timeString, "HH:mm:ss").format("hh:mm A");
    return newTime;
  }

  var dataForCSV = [
    [
      "AppointmentID",
      "Client",
      "Department",
      "Subdepartment",
      "StartTime",
      "EndTime",
      "Attendance",
      "Date",
      "ExcusedAbsence",
      "Updatedby",
      "Lastupdate",
    ],
  ];

  const storingData = () => {
    for (var i = 0; i < allReport.length; i++) {
      dataForCSV.push([
        allReport[i]["id"],
        allReport[i]["client_name"],
        allReport[i]["department_name"],
        allReport[i]["sub_department_name"],
        convertTimeToHHMM(allReport[i]["start_time"]),
        convertTimeToHHMM(allReport[i]["end_time"]),
        allReport[i]["attendance"] == 1 ? "Present" : "Absent",
        convertTimeToDate(allReport[i]["date"]),
        allReport[i]["excused_absence_comment"],
        allReport[i]["username"],
        allReport[i]["updated_at"],
      ]);
    }
  };

  const downloadCSV = async () => {
    setDownload(true);
    await storingData();
    setDownload(false);
  };

  return (
    <div className="  ">
      <button type="button" className="btn btn-success" onClick={downloadCSV}>
        Download CSV
      </button>{" "}
      &nbsp;&nbsp;
      {download ? <CSVDownload data={dataForCSV} target="_blank" /> : <></>}
      <br />
      <br />
      <div>
        <h5 className="text-center">Reports ({allReport.length})</h5>
        {role === "Super Admin" ? (
          <>
            <DataTable
              customStyles={customStyles}
              highlightOnHover
              onRowClicked={handleEventClick}
              columns={AdminColumns}
              data={allReport}
              pagination
            />
          </>
        ) : (
          <>
            <DataTable
              customStyles={customStyles}
              highlightOnHover
              onRowClicked={handleEventClick}
              columns={columns}
              data={allReport}
              pagination
            />
          </>
        )}
      </div>
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      {eventSelected && (
        <EventModal
          handleOpenEvent={handleOpenEvent}
          isOpen={isOpenEvent}
          event={eventSelected}
          updateAttendenceAPI={updateAttendenceAPI}
        />
      )}
    </div>
  );
};

export default FilterTable;
