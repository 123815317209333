import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";
import { toast } from "react-toastify";
import LogoutModal from "../../logoutModal/LogoutModal";

const AddADepartment = ({ isOpen, onClose }) => {
  const token = useAuth().token;
  const userId = useAuth().userId;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState("");
  const [color, setColor] = useState("#000000")
  const logout = useAuth().logout;
  const [isExpire, setIsExpire] = useState(false);

  const handleAddClick = () => {
    // e.preventDefault();
    // console.log(`color : `, color);

    // return ;
    const payload = {
      name: name,
      updatedBy: userId,
      color: color
    };
    // console.log(payload);
    fetch(`${base_url}/department/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        toast.success("Department Added Successful!");
        // console.log(response);
        setName("");
        onClose();
      })
      .catch((error) => {
        toast.warn("Failed to add Department!");
        console.error("Error adding Department:", error);
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add A Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isExpire && (
            <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
          )}
          <form
            className="w-full flex items-center justify-center"
            onSubmit={handleAddClick}
          >
            <div className="flex flex-col gap-3 border-2 w-5/6 bg-white p-10 rounded-lg">
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Department Name"
              />
            </div>
            <div className="d-flex align-items-center border border-2 bg-white ps-3 rounded mt-3 justify-content-between w-5/6 mx-auto">
            <label for="color">Select A color </label>
              <input
                type="color"
                id="color"
                className="form-control form-control-color w-25"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddClick}>
            Add Department
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddADepartment;
