import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/useAuth";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import LogoutModal from "../../logoutModal/LogoutModal";

const UpdateSubDepartment = ({ isOpen, onClose, data }) => {
  const token = useAuth().token;
  const userId = useAuth().userId;
  const role = useAuth().role;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [department, setDepartment] = useState(data[0].split(":")[1]);
  const [subdepartment, setSubdepartment] = useState(data[0].split(":")[2]);
  const [slotDuration, setSlotDuration] = useState(data[0].split(":")[3]);
  const logout = useAuth().logout;
  const [isExpire, setIsExpire] = useState(false);
  const [color, setColor] = useState(data[0].split(":")[6]);

  const handleAddClick = () => {
    if (subdepartment === "") {
      toast.warn("SubDepartment Should not be Empty!!");
      return;
    }

    if (slotDuration === "") {
      toast.warn("slotDuration Should not be Empty!!");
      return;
    }

    const payload = {
      SubDepartmentId: data[0].split(":")[4],
      SubDepartmentName: subdepartment,
      SlotDuration: slotDuration,
      updatedBy: userId,
      color : color
    };
    // return;

    fetch(`${base_url}/subdepartment/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        toast.success("subDepartment Updated Successful!");
        onClose();
      })
      .catch((error) => {
        console.error("Error Updating Department:", error);
      })
      .finally(() => {
        onClose();
      });
  };

  const handleDelete = () => {
    const apiUrl = `${base_url}/subdepartment/delete/${data[0].split(":")[4]}`;
    // alert("Are you sure you want to delete this sub department?");
    if(window.confirm("Are you sure you want to delete this sub department?") == false){
      toast.info("subdepartment deletion cancelled");
      return;
    }

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if(response.status === 404){
          toast.warn("appointment is already present for this subdepartment. Please delete that appointment first");
          return;
        }else if(response.status === 200){
          toast.success("subdepartment deleted successfully");
          return;
        }else{
          setIsExpire(true);
          return;
        }
      })
      .catch((error) => {
        toast.error("Error deleting subdepartment");
        console.error("Error sending data:", error);
      })
      .finally(() => {
        onClose();
      });
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Sub Department Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          <label htmlFor="department" className="">
            Department:
          </label>
          <input
            type="text"
            id="name"
            className="form-control "
            value={department}
            //   onChange={(e) => setdepartmentName(e.target.value)}
            placeholder="Department Name"
            disabled
          />
        </div>

        <div className="mb-2">
          <label className="" htmlFor="subdepartment">
            Sub Department:
          </label>
          <input
            type="text"
            className="form-control w-100 mt-1"
            id="subdepartment"
            value={subdepartment}
            onChange={(e) => setSubdepartment(e.target.value)}
          />
        </div>

        <div className="mb-2">
          <label className="" htmlFor="slotDuration">
            Slot Duration (in minutes):
          </label>
          <input
            type="number"
            className="form-control w-100 mt-1"
            id="slotDuration"
            value={slotDuration}
            onChange={(e) => setSlotDuration(e.target.value)}
          />
        </div>
        <div className="d-flex align-items-center border border-2 bg-white ps-3 rounded mt-3 justify-content-between w-5/6 mx-auto">
            <label for="color">Select A color </label>
              <input
                type="color"
                id="color"
                className="form-control form-control-color w-25"
                value={color}
                onChange={(e) => setColor(e.target.value)}
              />
            </div>
        {isExpire && (
          <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        {
            role === "Super Admin" && <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
          }
        <Button variant="primary" onClick={handleAddClick}>
          Update Sub Department
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateSubDepartment;
