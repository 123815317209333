import { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import AddClient from "./AddClient";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { CSVDownload } from "react-csv";
import { useAuth } from "../../../context/useAuth";
import UpdateClient from "./UpdateClient";
import { useLocation } from "react-router-dom";
import moment from "moment";
import LogoutModal from "../../logoutModal/LogoutModal";
import DataTable from "react-data-table-component";
import { ScaleLoader } from "react-spinners";


const Clients = () => {
  const location = useLocation();
  const { Clientvalue } = location.state || "";
  const programId = useAuth().programId;
  const today = new Date(Date.now());
  const token = useAuth().token;
  const role = useAuth().role;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [clients, setClients] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [client, setClient] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [Departments, setDepartments] = useState([]);
  const [Subdepartments, setSubdepartments] = useState([]);
  const [data1, setData1] = useState([]);
  var [data2, setData2] = useState([]);
  var [data3, setData3] = useState([]);
  const [ComplianceRate, setComplianceRate] = useState(0);
  const [activeDropdown, setActiveDropdown] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredValue, setFilteredValue] = useState(Clientvalue);
  const [isActionModalopen, setIsActionModalOpen] = useState(false);
  const [actionModalData, setActionModalData] = useState([]);
  const [isExpire, setIsExpire] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);

  const clientsColumns = [
    {
      name: "Id",
      selector: (row) => row.ClientID,
      sortable: true,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "20%",
    },
    {
      name: "Intake Date",
      selector: (row) => convertTimeToDate(row.IntakeDate),
      sortable: true,
      width: "10%",
    },
    {
      name: "Program Completion Date",
      selector: (row) => convertTimeToDate(row.ProgramCompletionDate),
      sortable: true,
      width: "15%",
      cell: row => (
        <div style={{ color: new Date(row.ProgramCompletionDate).getTime() <= today.getTime() ? '#BB2B3D' : 'black', fontWeight: new Date(row.ProgramCompletionDate).getTime() <= today.getTime() ? 'bold' : '' }}>
          {convertTimeToDate(row.ProgramCompletionDate)}
        </div>
      ),
    },
    {
      name: "Active Status",
      selector: (row) => (row.ActiveStatus == 1 ? "Active" : "Inactive"),
      sortable: true,
      width: "10%",
      cell: row => (
        <div style={{ color: row.ActiveStatus == 1 ? 'black' : '#BB2B3D', fontWeight: row.ActiveStatus == 1 ? '' : 'bold' }}>
          {row.ActiveStatus == 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row) => (row.Email == "" ? "No Email" : row.Email),
      sortable: true,
      width: "15%",
    },
    {
      name: "Phone",
      selector: (row) => (row.Phone == "" ? "No Phone" : row.Phone),
      sortable: true,
      width: "15%",
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={() => handleActionClick(row.ClientID)}
        >
          Action
        </button>
      ),
      width: "10%",
    },
  ];


  const clientsColumnsForSuperAdmin = [
    {
      name: "Id",
      selector: (row) => row.ClientID,
      sortable: true,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "15%",
    },
    {
      name: "Intake Date",
      selector: (row) => convertTimeToDate(row.IntakeDate),
      sortable: true,
      width: "10%",
    },
    {
      name: "Program Completion Date",
      selector: (row) => convertTimeToDate(row.ProgramCompletionDate),
      sortable: true,
      width: "12%",
      cell: row => (
        <div style={{ color: new Date(row.ProgramCompletionDate).getTime() <= today.getTime() ? '#BB2B3D' : 'black', fontWeight: new Date(row.ProgramCompletionDate).getTime() <= today.getTime() ? 'bold' : '' }}>
          {convertTimeToDate(row.ProgramCompletionDate)}
        </div>
      ),
    },
    {
      name: "Active Status",
      selector: (row) => (row.ActiveStatus == 1 ? "Active" : "Inactive"),
      sortable: true,
      width: "8%",
      cell: row => (
        <div style={{ color: row.ActiveStatus == 1 ? 'black' : '#BB2B3D', fontWeight: row.ActiveStatus == 1 ? '' : 'bold' }}>
          {row.ActiveStatus == 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row) => (row.Email == "" ? "No Email" : row.Email),
      sortable: true,
      width: "10%",
    },
    {
      name: "Phone",
      selector: (row) => (row.Phone == "" ? "No Phone" : row.Phone),
      sortable: true,
      width: "10%",
    },
    {
      name: "Updated by",
      selector: (row) => (row.updated_by ),
      sortable: true,
      width: "10%",
    },
    {
      name: "Created by",
      selector: (row) => (row.created_by ),
      sortable: true,
      width: "10%",
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={() => handleActionClick(row.ClientID)}
        >
          Action
        </button>
      ),
      width: "10%",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8", 
        color: "black", 
        fontSize: "15px", 
        fontWeight: "bold", 
        textAlign: "left", 
      },
    },
    rows: {
      style: {
        fontSize: "15px", 
        color: "black", 
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  const singleClientColumns = [
    {
      name: "Id",
      selector: (row) => row.ClientID,
      sortable: true,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => findValueAfterColon("client", row.ClientID),
      sortable: true,
      width: "300px",
    },
    {
      name: "Intake Date",
      selector: (row) => convertTimeToDate(row.IntakeDate),
      sortable: true,
      width: "150px",
    },
    {
      name: "Program Completion Date",
      selector: (row) => convertTimeToDate(row.ProgramCompletionDate),
      sortable: true,
      width: "150px",
      cell: row => (
        <div style={{ color: new Date(row.ProgramCompletionDate).getTime() <= today.getTime() ? '#BB2B3D' : 'black', fontWeight: new Date(row.ProgramCompletionDate).getTime() <= today.getTime() ? 'bold' : '' }}>
          {convertTimeToDate(row.ProgramCompletionDate)}
        </div>
      ),
    },
    {
      name: "Active Status",
      selector: (row) => `${row.ActiveStatus == 1 ? "Active" : "Inactive"}`,
      sortable: true,
      cell: row => (
        <div style={{ color: row.ActiveStatus == 1 ? 'black' : '#BB2B3D', fontWeight: row.ActiveStatus == 1 ? '' : 'bold' }}>
          {row.ActiveStatus == 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      name: "Compliance Rate",
      selector: (row) => `${ComplianceRate}`,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => `${row.Email == undefined ? "No Email" : row.Email}`,
      sortable: true,
      width: "280px",
    },
    {
      name: "Phone",
      selector: (row) => `${row.Phone == undefined ? "No Phone" : row.Phone}`,
      sortable: true,
      width: "180px",
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          className="btn btn-primary"
          onClick={() => handleActionClick(row.ClientID)}
        >
          Action
        </button>
      ),
      width: "150px",
    },
  ];

  const appointmentColumns = [
    {
      name: "Id",
      selector: (row) => row.AppointmentID,
      sortable: true,
      width: "5%",
    },
    {
      name: "Client",
      selector: (row) => findValueAfterColon("client", row.ClientID),
      sortable: true,
      width: "20%",
    },
    {
      name: "Department",
      selector: (row) => row.DepartmentName,
      sortable: true,
      width: "15%",
    },
    {
      name: "Subdepartment",
      selector: (row) => row.SubdepartmentName,
      sortable: true,
      width: "15%",
    },
    {
      name: "Start Time",
      selector: (row) => convertTimeToHHMM(row.StartTime),
      sortable: true,
      width: "10%",
    },
    {
      name: "End Time",
      selector: (row) => convertTimeToHHMM(row.EndTime),
      sortable: true,
      width: "10%",
    },
    {
      name: "Attendance",
      selector: (row) => row.Attendance ? ((row.excused_absence_comment === null) ? ("Present") : ("Excused Absence")) : row.Attendance === 0 ? "Absent" : new Date(row.Date) <= new Date() ? "Unchecked" : "Future Booking",
      sortable: true,
      width: "10%",
    },
    {
      name: "Date", 
      selector: (row) => convertTimeToDate(row.Date),
      sortable: true,
      width: "15%",
    },
  ]

  const [download, setDownload] = useState(false);

  var dataForCSV = [
    [
      "AppointmentID",
      "Client",
      "Department",
      "Subdepartment",
      "StartTime",
      "EndTime",
      "Status",
      "Attendance",
      "Date",
    ],
  ];

  var [csvData, setCsvData] = useState(dataForCSV);
  function downloadCSV() {
    setDownload(true);
    setTimeout(() => {
      setDownload(false);
    }, 500);
  }

  function findValueAfterColon(type, Id) {
    if (type == "dept") {
      for (var i = 0; i < Departments.length; i++) {
        if (Departments[i].split(":")[0] == Id.toString()) {
          return Departments[i].split(":")[1];
        }
      }
    } else if (type == "subdept") {
      for (var j = 0; j < Subdepartments.length; j++) {
        if (Subdepartments[j].split(":")[0] == Id.toString()) {
          return Subdepartments[j].split(":")[1];
        }
      }
    } else {
      for (var k = 0; k < clients.length; k++) {
        if (clients[k].split(":")[0] == Id.toString()) {
          return clients[k].split(":")[1];
        }
      }
    }
  }

  function convertTimeToDate(dateString) {
    const newDate = moment.utc(dateString).format("MMM Do YYYY");
    return newDate;
  }

  function convertTimeToHHMM(timeString) {
    const newTime = moment(timeString, "HH:mm:ss").format("hh:mm A");
    return newTime;
  }

  const handleTypeaheadChange = (selected) => {
    setClient(selected);
  };

  const handleClientButtonClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await getClients();
  };

  const convertDateTime = (dateString) => {
    const newDate = moment.utc(dateString).local().format("MMM Do YYYY");
    return newDate;
  };

  const getClients = async () => {
    try {
      const payload = {
        programId: programId,
      }
      // console.log(`payload : `, payload)
      const response = await fetch(`${base_url}/client/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      // console.log(`new data : `, data);

      var temp = [];
      var temp2 = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["client_name"]}`);
          temp2.push(data[i]);
        }
        resolve(temp);
      });

      var temp1 = [];

      for (var i = 0; i < temp2.length; i++) {
        temp1.push({
          ClientID: temp2[i]["id"],
          name: temp2[i]["client_name"],
          IntakeDate: temp2[i]["program_intake_date"],
          ProgramCompletionDate: temp2[i]["program_completion_date"],
          ActiveStatus: temp2[i]["status"],
          ComplianceRate: temp2[i]["ComplianceRate"],
          Email: temp2[i]["email"],
          Phone: temp2[i]["phone"],
          updated_at: temp2[i]["updated_at"],
          updated_by: temp2[i]["username"],
          created_by : temp2[i]["created_by"]
        });
      }

      setClients(temp);
      // console.log(`data : `, temp1);
      setFilteredData(temp1);
      setData2(temp1);
      setClientLoading(false);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    var clientID = await new Promise((resolve, reject) => {
      clients.forEach((elem) => {
        if (elem.includes(client[0])) {
          resolve(elem.split(":")[0]);
        }
      });
      resolve(-1);
    });

    setData3(data2.filter((elem) => elem["ClientID"] == clientID));

    const apiUrl = `${base_url}/appointment/getByClient`;
    var payload = {
      clientID: clientID,
      programId: programId,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };

    fetch(apiUrl, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        var result = await response.json();

        // console.log(`result : `, result);
        var temp = [];
        var sum = 0;

        for (var i = 0; i < result.length; i++) {
          sum = sum + result[i]["attendance"];
          temp.push({
            AppointmentID: result[i]["id"],
            ClientID: result[i]["client_id"],
            ClientName: result[i]["client_name"],
            DepartmentName: result[i]["department_name"],
            SubdepartmentName: result[i]["sub_department_name"],
            StartTime: result[i]["start_time"],
            EndTime: result[i]["end_time"],
            // Status: result[i]["Status"],
            Attendance: result[i]["attendance"],
            excused_absence_comment: result[i]["excused_absence_comment"],
            Date: result[i]["date"],
          });
          dataForCSV.push([
            result[i]["id"],
            findValueAfterColon("client", result[i]["client_id"]),
            findValueAfterColon("dept", result[i]["department_id"]),
            findValueAfterColon("subdept", result[i]["sub_department_id"]),
            convertTimeToHHMM(result[i]["start_time"]),
            convertTimeToHHMM(result[i]["end_time"]),
            // result[i]["Status"] == 1 ? "Active" : "Inactive",
            result[i]["attendance"]
              ? "Present"
              : result[i]["attendance"]
              ? "Absent"
              : "Null",
            convertTimeToDate(result[i]["date"]),
          ]);
        }

        setData1(temp);

        var length = temp.length;
        var Total = parseInt(sum) / parseInt(length);
        setComplianceRate(Total * 100);

        setVisible(true);
        setActiveDropdown(false);
        setCsvData(dataForCSV);
        setLoading(false);
      })
      .then((data) => {
        // console.log("Data sent successfully:", data);
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function handleClientDropdownChange(e) {
    setFilteredValue(e);

    if (e === "All") {
      setFilteredData(data2);
    }

    if (e === "Active") {
      setFilteredData(data2.filter((element) => element.ActiveStatus === 1));
    }

    if (e === "Inactive") {
      setFilteredData(data2.filter((element) => element.ActiveStatus === 0));
    }
  }

  const handleActionClick = (e) => {
    const newData = filteredData.filter((value) => {
      return value.ClientID === e;
    });

    setActionModalData(newData);
    setIsActionModalOpen(true);
  };

  const closeActionModal = async () => {
    setIsActionModalOpen(false);
    await getClients();
  };

  useEffect(() => {
    setClientLoading(true);
    getClients();
  }, []);

  useEffect(() => {
    handleClientDropdownChange(Clientvalue);
  }, [data2]);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4">Clients</h3>
      <hr />
      <button
        type="button"
        onClick={handleClientButtonClick}
        className="btn btn-primary mb-3"
      >
        Add a Client
      </button>
      <form onSubmit={handleSubmit} className="">
        <div className=" row">
          <div className="row mt-2 w-75 mb-3">
            <Typeahead
              className="w-50"
              id="client"
              onChange={handleTypeaheadChange}
              options={clients.map((elem) => elem.split(":")[1])}
              selected={client}
              placeholder="Select a Client"
            />
            {loading ? (
              <div className="" role="status">
                <span className="">Loading...</span>
              </div>
            ) : (
              <button
                type="Submit"
                className="btn btn-primary "
                style={{ width: "100px" }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
        <AddClient isOpen={isModalOpen} onClose={closeModal} />
      </form>
      {activeDropdown && (
        <div>
          <select
            id="dropdown"
            value={filteredValue}
            onChange={(e) => {
              handleClientDropdownChange(e.target.value);
            }}
            className="form-select w-25 mt-3"
            aria-label="Default select example"
          >
            <option value="All">All Clients</option>
            <option value="Active">Active Clients</option>
            <option value="Inactive">InActive Client</option>
          </select>
        </div>
      )}

      <br />
      {visible ? (
        <>
          <button
            type="button"
            className="btn btn-success"
            onClick={downloadCSV}
          >
            Download CSV
          </button>{" "}
          &nbsp;&nbsp;
          {download ? (
            <CSVDownload data={csvData} target="_blank" />
          ) : (
            <></>
          )}{" "}
          <hr />
          <div>
        <h5 className="text-center">Appointment Details ({data1.length})</h5>
        <DataTable  columns={appointmentColumns} data={data1} pagination customStyles={customStyles}
          highlightOnHover />
        </div>
          
          <br /> <br />
                
          <DataTable columns={singleClientColumns} data={data3} pagination customStyles={customStyles}
          highlightOnHover />
          
          {isActionModalopen && (
                <UpdateClient
                  isOpen={isActionModalopen}
                  onClose={closeActionModal}
                  data={actionModalData}
                />
              )}
        </>
      ) : (
        <>
        {
            clientLoading === true ? (<>
              <div className="w-100 h-100 d-flex align-items-center justify-content-center"><ScaleLoader size={60} />
              </div></>) : (
              <>
                {
          role === "Super Admin" ? (<DataTable  columns={clientsColumnsForSuperAdmin} data={filteredData} pagination customStyles={customStyles}
            highlightOnHover/>) : (<DataTable  columns={clientsColumns} data={filteredData} pagination customStyles={customStyles}
            highlightOnHover/>)
        }
              </>
            )
          }
        
          
          {isActionModalopen && (
            <UpdateClient
              isOpen={isActionModalopen}
              onClose={closeActionModal}
              data={actionModalData}
            />
          )}
          
        </>
      )}
    </div>
  );
};

export default Clients;
